import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

import { contractStatusOptions } from "../../../lib/constants";

const makeFilters = (role) => {
  const filters = [
    <ReferenceInput source="contracts.carrier" reference="carrier">
      <SelectInput fullwidth="true" optionText="value" />
    </ReferenceInput>,
    <ReferenceInput source="specialist" label="COB" reference="user">
      <AutocompleteInput fullwidth />
    </ReferenceInput>,
    <TextInput source="npn" label="NPN" />,
    <TextInput source="name" label="Name" />,
    <TextInput source="email" label="Email" />,
    <TextInput source="phone" label="Phone" />,
    <TextInput source="topOfHierarchy" label="Top of Hierarchy" />,
    <SelectInput
      source="status"
      fullwidth="true"
      choices={contractStatusOptions}
    />,
    <DateInput source="createdAt!GTE" label="After" />,
    <DateInput source="createdAt!LTE" label="Before" />,
    <BooleanInput source="unknownAgent" label="Unknown Agent" />,
  ];
  if (role === "admin") {
    filters.push(<BooleanInput source="userStates" label="My States" />);
    filters.push(<ReferenceInput source="agency" label="Agency" reference="agency">
                    <SelectInput fullwidth="true" label="Agency" />
                 </ReferenceInput>);
  }
  return filters;
};

export default makeFilters;

import { Datagrid, List, TextField } from 'react-admin';

export const AgencyList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="domain" />
        </Datagrid>
    </List>
);

export default AgencyList;

import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import LoginForm from "../components/Login";

const Login = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <Layout>
      <Box sx={{ padding: 4 }}>
        <LoginForm />
      </Box>
    </Layout>
  );
};

export default Login;

import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Autocomplete,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";

import { Box } from "@mui/system";

const InputStyle = {
  marginBottom: 2,
};

const CarrierSelection = ({
  carrierList,
  updateOptions,
  carriers,
  setCarriers,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  const unselectedCarriers = carrierList.filter((carrier) => {
    return !carriers.some((c) => c.value === carrier.id);
  });

  function push(carrier) {
    setCarriers([...carriers, carrier]);
  }

  function remove(c) {
    setCarriers(carriers.filter((carrier) => carrier.value !== c.id));
  }

  if (!carrierList) return <></>;

  return (
    <>
      {carriers.length > 0 && (
        <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Carrier selection">
            <TableHead>
              <TableRow>
                <TableCell>Carrier</TableCell>
                <TableCell>State</TableCell>
                <TableCell align="right">New/Transfer</TableCell>
                <TableCell align="right">Individual/Corp</TableCell>
                <TableCell align="right">With Release</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carriers.map((row, index) => {
                let carrier = {};
                carrierList.forEach((c) => {
                  if (c.id === row.value) carrier = c;
                });

                return (
                  <TableRow
                    key={carrier.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell scope="row">{carrier.value}</TableCell>
                    <TableCell align="left">
                      {carrier.states?.length ? (
                        <Select
                          value={row.states}
                          displayEmpty={true}
                          renderValue={(val) => {
                            if (!val) return <em>Select states</em>;
                            return val.join(", ");
                          }}
                          onChange={(event) => {
                            updateOptions("states", row, event);
                          }}
                          sx={{ ...InputStyle, minWidth: 150 }}
                          required={true}
                          multiple={true}
                        >
                          {carrier.states.map((state) => {
                            return (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Select
                        value={row.policyCreation}
                        displayEmpty={true}
                        onChange={(event) => {
                          updateOptions("policyCreation", row, event);
                        }}
                        sx={{ ...InputStyle, minWidth: 150 }}
                      >
                        <MenuItem value="">
                          <em>New/Transfer</em>
                        </MenuItem>
                        <MenuItem value={"new"}>New</MenuItem>
                        <MenuItem value={"transfer"}>Transfer</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="right">
                      <Select
                        displayEmpty={true}
                        value={row.policyEntity}
                        onChange={(event) => {
                          updateOptions("policyEntity", row, event);
                        }}
                        sx={{ ...InputStyle, minWidth: 150 }}
                      >
                        <MenuItem value="">
                          <em>Individual/Corp</em>
                        </MenuItem>
                        <MenuItem value={"individual"}>Individual</MenuItem>
                        <MenuItem value={"corporate"}>Corporate</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="right">
                      {row.policyCreation === "transfer" && (
                        <Checkbox
                          value={row.withRelease}
                          onChange={(event) => {
                            updateOptions("withRelease", row, event);
                          }}
                          sx={{ ...InputStyle, minWidth: 150 }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          remove(carrier);
                        }}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FormControl fullWidth>
        <Box
          sx={{
            width: "100%",
            marginBottom: 3,
            bgcolor: "background.paper",
          }}
          component={Paper}
        >
          <Autocomplete
            disablePortal
            fullWidth={true}
            onChange={(event, carrier) => {
              if (!carrier) {
                console.warn("no carrier selected");
                return;
              }
              const newCarrier = {
                value: carrier.id,
                policyEntity: "",
                policyCreation: "",
                withRelease: false,
                states: [],
              };
              push(newCarrier);
              setInputValue("");
              setValue(null);
            }}
            inputValue={inputValue}
            value={value}
            getOptionLabel={(option) => option.value}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "reset") {
                setInputValue("");
                return;
              }
              if (reason === "clear") {
                setInputValue("");
                return;
              }
              setInputValue(newInputValue);
            }}
            options={unselectedCarriers}
            renderInput={(params) => (
              <TextField {...params} fullWidth={true} label="Add Carrier" />
            )}
          />
        </Box>
      </FormControl>
    </>
  );
};

export default CarrierSelection;

import {
  Edit,
} from "react-admin";
import { useAuth } from "../../../hooks/useAuth";
import { CarrierTitle } from "./index";
import CarrierFields from "./Fields";

export const CarrierEdit = () => {
  const { user, isAgencyAdmin, isGarityAdmin } = useAuth();

  return (
    <Edit title={<CarrierTitle />}>
      <CarrierFields
        user={user}
        isAgencyAdmin={isAgencyAdmin}
        isGarityAdmin={isGarityAdmin}
      />
    </Edit>
  );
};
export default CarrierEdit;

import axios from "axios";
const API_URL = "/api/agency";

const defaultAgency = {
  name: "Garity Advantage",
  logo: {
    src: "/logo.png",
    title: "Garity Advantage",
    isDefault: true,
  },
  isDefault: true,
  isPURL: false,
};

export let _agency = null;

export async function fetchCurrent() {
  if (_agency) {
    return _agency;
  }
  const response = await axios.get(`${API_URL}/current`);
  const isPURL = !!response.data;
  const currentAgency = response.data || defaultAgency;
  if (!!currentAgency.domain?.length) {
    const urlBase = `${window.location.protocol}//${currentAgency.domain}`;
    currentAgency.mainURL = new URL(
      "/",
      urlBase
    );
    currentAgency.adminURL = new URL(
      "/admin",
      urlBase
    );
  } else {
    currentAgency.mainURL = new URL("/", window.location.href);
    currentAgency.adminURL = new URL("/admin", window.location.href);
  }
  if (!currentAgency.logo?.src?.length) {
    currentAgency.logo = defaultAgency.logo;
  }
  currentAgency.isPURL = isPURL;
  return currentAgency;
}

const AgencyService = {
  current: fetchCurrent,
  clear: () => {
    _agency = null;
  },
};

export default AgencyService;

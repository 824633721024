import React from 'react';
import Admin from '../components/Admin';

const AdminRoute = () => {
    return (
        <Admin />
    );
};

export default AdminRoute;

import React from "react";
import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { humanize } from "inflected";

// export type ValueFieldProps = {
//   value: string;
//   label: string;
//   source: string;
// };

export const ValueField = ({ value, source, label, transform, ...props }) => {
  const record = useRecordContext();

  // If the value is undefined or null, try to read it from source. If that
  // fails, the value is the full record.
  if (value == undefined || value == null) {
    if (source) {
      value = record[source];
    } else if (!!transform) {
      value = record;
    }
  }
  if (typeof value === "undefined") {
    value = null;
  }
  if (transform) {
    value = transform(value);
  }
  const sx = {
    display: "flex",
    flexDirection: "column",
    color: "rgba(0,0,0,.87)",
    backgroundColor: "rgba(0,0,0,.04)",
    borderBottom: "1px solid rgba(0,0,0,.87)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    paddingLeft: "0.7rem",
    paddingRight: "0.7rem",
    paddingTop: "0.2rem",
    marginTop: "8px",
    ...props.sx,
  };
  return (
    <Box
      sx={sx}
    >
      <Typography variant="caption" sx={{textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{label || humanize(source)}</Typography>
      <Typography variant="body1">{value || " "}</Typography>
    </Box>
  );
};

import React from 'react';
import { useRecordContext } from 'react-admin';

import AgencyEdit from './Edit';
import AgencyList from './List';
import AgencyCreate from './Create';

const AgencyTitle = () => {
    const record = useRecordContext();
    return (
        <>
            {record ? `${record.name}` : 'Untitled agency' }
        </>
    )
}

export { AgencyEdit, AgencyList, AgencyCreate, AgencyTitle };

import axios from "axios";
const API_URL = "/api/agent";

const list = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const findByNPN = (npn) => {
    return axios
      .get(API_URL, { params: { npn } })
      .then((response) => {
        if (response.data.length > 1) {
          throw new Error("Multiple agents found with NPN " + npn);
        };
        if (response.data.length === 0) {
          return null;
        }
        return response.data[0];
      });
  };

const importAgents = async (formData) => {
  const response = await axios.postForm(API_URL, formData);
  return response;
};

const AgentService = {
  list,
  importAgents,
  findByNPN,
};

export default AgentService;

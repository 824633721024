import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { Formik, Field, Form } from "formik";

import { Button, Alert } from "@mui/material";

import AuthService from "../../services/auth.service";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(40, "Password must not exceed 40 characters"),
  passwordConfirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const messageStyle = {
  marginBottom: 2,
};

const Reset = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  const handleReset = (data) => {
    setMessage("");
    setLoading(true);

    AuthService.reset(userId, token, data.password).then(
      () => {
        navigate("/");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          error.response?.data?.message ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="reset-form">
      {message && (
        <Alert sx={messageStyle} severity="error">
          {message}
        </Alert>
      )}
      {(!token || !userId) && (
        <Alert sx={messageStyle} severity="error">
          Invalid Link, Please Request New Reset E-Mail
        </Alert>
      )}
      <Formik
        initialValues={{
          password: "",
          passwordConfirmation: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleReset(values);
        }}
      >
        {({ values, onBlur, onChange }) => (
          <Form>
            <Field
              name="password"
              label="New Password"
              type="password"
              fullWidth
              component={FormInput}
              variant="outlined"
            />
            <Field
              name="passwordConfirmation"
              label="Confirm Password"
              type="password"
              fullWidth
              component={FormInput}
              variant="outlined"
            />
            <Button
              type="submit"
              disabled={loading ? true : false}
              fullWidth
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Reset;

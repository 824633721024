import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import exporter from "./exporter";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import makeFilters from "./Filter";
import { contractStatusLabels } from "../../../lib/constants";

function ContractRequestTable(props) {
  const title = props.disableAppbarTitle ? " " : null;
  const { user, isGarityAdmin } = useAuth();

  return (
    <List
      {...props}
      title={title}
      filters={makeFilters(user.role)}
      exporter={exporter}
    >
      <>
        <Typography p={2} variant="h6">
          {props.title}
        </Typography>
        <Datagrid rowClick="edit">
          <DateField locale="en-US" options="short" source="createdAt" />
          <TextField source="npn" label="NPN" />
          <BooleanField
            width={60}
            source="unknownAgent"
            label="Unknown Agent"
            FalseIcon={null}
            TrueIcon={WarningAmberIcon}
          />
          {isGarityAdmin(user) && (
            <ReferenceField label="Agency" source="agency" reference="agency">
              <TextField source="name" />
            </ReferenceField>
          )}
          <ReferenceField link={false} label="COB" source="specialist" reference="user">
            <FunctionField label="COB"
              render={(record) => `${record.firstName} ${record.lastName}`}
              source="specialist" />
          </ReferenceField>
          <TextField source="phone" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="topOfHierarchy" />
          <FunctionField
            render={(record) => contractStatusLabels[record.status]}
            source="status"
          />
        </Datagrid>
      </>
    </List>
  );
}

export const ContractList = () => (
  <Stack direction="column">
    <ContractRequestTable />
  </Stack>
);

export default ContractList;

import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  BooleanField,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import { makeFilters } from "./Filter";
import { useAuth } from "../../../hooks/useAuth";
import exporter from "./exporter";
import { contractStatusLabels, gaApprovalLabels } from "../../../lib/constants";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function GAContractTable(props) {
  let title = props.disableAppbarTitle ? " " : null;
  const { user, isGarityAdmin } = useAuth();

  return (
    <List
      {...props}
      title={title}
      filters={makeFilters(user.role)}
      exporter={exporter}
    >
      <>
        <Typography p={2} variant="h6">
          {props.title}
        </Typography>
        <Datagrid rowClick="edit">
          <DateField locale="en-US" options="short" source="createdAt" />
          <TextField label="NPN" source="agentSnapshot.npn" />
          <BooleanField
            width={60}
            source="unknownAgent"
            label="Unknown Agent"
            FalseIcon={null}
            TrueIcon={WarningAmberIcon}
          />
          {isGarityAdmin(user) && (
            <ReferenceField label="Agency" source="agency" reference="agency">
              <TextField source="name" />
            </ReferenceField>
          )}
          <TextField label="First name" source="agentSnapshot.firstName" />
          <TextField label="Last/Company name" source="agentSnapshot.lastName" />

          <ReferenceField link={false} label="COB" source="specialist" reference="user">
            <FunctionField label="COB"
              render={(record) => `${record.firstName} ${record.lastName}`}
              source="specialist" />
          </ReferenceField>
          <FunctionField
            render={(record) => contractStatusLabels[record.status]}
            source="status"
          />
          <FunctionField
            render={(record) => gaApprovalLabels[record.approval]}
            source="approval"
          />
        </Datagrid>
      </>
    </List>
  );
}

export const GAContractList = () => (
  <Stack direction="column">
    <GAContractTable />
  </Stack>
);

export default GAContractList;

import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

const statusLabel = {
  submitted: "Submitted",
  complete: "In SmartOffice",
};

const formatUser = (user) => {
  if (!user) {
    return "";
  }
  return `${user.firstName} ${user.lastName} (${user.email})`;
};

// transformContractRequest takes a contract request and breaks it out into individual contracts
function transformContractRequest(contractRequest, carriers, agencies, users, specialists) {
  const {
    id,
    npn,
    firstName,
    lastName,
    phone,
    email,
    address,
    city,
    state,
    zip,
    upline,
    payment,
    paymentNpn,
    paymentCorporation,
    paymentTaxId,
    paymentUpline,
    notes,
    user: userId,
    specialist: specialistId,
    agent,
    agency,
    emailRecipients,
    createdAt,
    updatedAt,
  } = contractRequest;
  const emailRecipientList = emailRecipients.join("; ");
  const user = users[userId];
  const specialist = specialists[specialistId]
  const userDescription = user ? `${user.firstName} ${user.lastName} (${user.email})` : "Unknown";
  return contractRequest.contracts.map((contract) => {
    return {
      "Contract Request ID": id,
      "Carrier Name": carriers[contract.carrier]?.value || "Unknown",
      "Carrier Option": contract.carrierOption,
      "Agent NPN": npn,
      "Agent First Name": firstName,
      "Agent Last Name": lastName,
      "Agent Email": email,
      "Agent Phone": phone,
      "Agent Address": address,
      "Agent City": city,
      "Agent State": state,
      "Agent ZIP": zip,
      "Agent Upline": upline,
      "Agent in database": agent ? "Yes" : "No",
      "Payment Option": payment,
      "Payment NPN": paymentNpn,
      "Payment Corporation": paymentCorporation,
      "Payment Tax ID": paymentTaxId,
      "Payment Upline": paymentUpline,
      Status: statusLabel[contract.status],
      "COB": formatUser(specialist),
      Agency: agencies[agency?.id]?.name || "N/A",
      "Submitted By": userDescription,
      "Email Recipients": emailRecipientList,
      Notes: notes,
      Created: createdAt,
      Updated: updatedAt,
    };
  });
}

const headers = [
  "Contract Request ID",
  "Carrier Name",
  "Carrier Option",
  "Agent NPN",
  "Agent First Name",
  "Agent Last Name",
  "Agent Email",
  "Agent Phone",
  "Agent Address",
  "Agent City",
  "Agent State",
  "Agent ZIP",
  "Agent Upline",
  "Agent in database",
  "Payment Option",
  "Payment NPN",
  "Payment Corporation",
  "Payment Tax ID",
  "Payment Upline",
  "Status",
  "COB",
  "Agency",
  "Submitted By",
  "Email Recipients",
  "Notes",
  "Created",
  "Updated",
];

const exporter = async (records, fetchRelatedRecords) => {
  const agencies = await fetchRelatedRecords(records, "agency", "agency");
  const contracts = records.map((cr) => cr.contracts);
  const carriers = await fetchRelatedRecords(contracts, "carrier", "carrier");
  const users = await fetchRelatedRecords(contracts, "user", "user");
  const specialists = await fetchRelatedRecords(contracts, "specialist", "user");

  const data = records.map((cr) =>
    transformContractRequest(cr, carriers, agencies, users, specialists)
  );
  const report = await jsonExport(data, { headers });
  return downloadCSV(report, "Contract Requests");
};

export default exporter;

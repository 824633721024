import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import { useAgency } from "../hooks/useAgency";
import { Button } from "@mui/material";

export default function Home() {
  const navigate = useNavigate();
  const { agency, loading: agencyLoading } = useAgency();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function runRedirect() {
      // Redirect based on user: if user is logged in, redirect to admin dashboard
      const user = await authService.getCurrentUser();
      if (user) {
        navigate("/admin");
        return;
      }

      // Cannot continue yet because we don't know if we are in a PURL or if the agency is just loading
      if (agencyLoading) {
        return;
      }

      // If we're not logged in, and are on a PURL, redirect to contract entry
      if (agency?.isPURL) {
        navigate("/contract");
        return;
      }

      // Otherwise just render a regular home page
      setLoading(false);
    }
    runRedirect();
  }, [agency, agencyLoading]);

  if (loading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}>
      <Button variant="contained" onClick={() => navigate("/admin")}>
        Admin area (login)
      </Button>
      <Button variant="contained" onClick={() => navigate("/contract")}>
        Contract request
      </Button>
    </div>
  );
}

import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import InviteForm from "../components/Invite";

const Invite = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <Layout>
      <Box sx={{ padding: 4 }}>
        <InviteForm />
      </Box>
    </Layout>
  );
};

export default Invite;

import React from 'react';
import { Button } from '@mui/material';

import * as Yup from 'yup';
import SelectInput from '../SelectInput';
import FormInput from '../FormInput';
import { Formik, Field, Form } from "formik";
import { paymentOptions } from '../../lib/constants';

const validationSchema = Yup.object().shape({
    payment: Yup.string().required("Payment method is required"),
});

function validateField(value) {
    let error;
    if (!value) error = 'Required Field';
    return error;
}

const pifPaymentOptions = paymentOptions.filter((x) => ["me","upline","corporation"].includes(x.id));

const Payment = ({ data, completeStep, user }) => {
    const submit = (data) => {
        completeStep(data);
    };
    return (
        <>
            <Formik
                initialValues={{
                    payment: data?.payment ?? "",
                    paymentNpn: data?.paymentNpn ?? "",
                    paymentCorporation: data?.paymentCorporation ?? "",
                    paymentTaxId: data?.paymentTaxId ?? "",
                    paymentUpline: data?.paymentUpline ?? "",
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    submit(values);
                }}>
                {({ values, onBlur, onChange }) => (
                    <Form>
                        <Field
                            name="payment"
                            fullWidth
                            component={SelectInput}
                            variant="outlined"
                            placeholder="Select Payment Method"
                            options={pifPaymentOptions}
                        />
                        {values['payment'] === "corporation" && <>
                            <Field
                                name="paymentCorporation"
                                label="Corporation Name"
                                validate={validateField}
                                fullWidth
                                component={FormInput}
                                variant="outlined"
                            />
                            <Field
                                name="paymentNpn"
                                label="Corporation NPN"
                                validate={validateField}
                                fullWidth
                                component={FormInput}
                                variant="outlined"
                            />
                            <Field
                                name="paymentTaxId"
                                label="Tax ID"
                                validate={validateField}
                                fullWidth
                                component={FormInput}
                                variant="outlined"
                            />
                        </>
                        }
                        {values['payment'] === "upline" && <>
                            <Field
                                name="paymentUpline"
                                label="Name of Upline"
                                fullWidth
                                validate={validateField}
                                component={FormInput}
                                variant="outlined"
                            />
                        </>
                        }
                        <Button type="submit" fullWidth variant="contained">Next</Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Payment;

// in src/MyLayout.js
import * as React from "react";
import { forwardRef } from "react";
import {
  AppBar,
  Menu,
  Layout,
  UserMenu,
  useResourceDefinitions,
  TitlePortal,
} from "react-admin";
import { Box, Button, Menu as MuiMenu, MenuItem } from "@mui/material";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import ClipboardIcon from "@mui/icons-material/Assignment";
import AddIcon from "@mui/icons-material/AddToQueue";
import { useAuth } from "../../hooks/useAuth";
import { useAgency } from "../../hooks/useAgency";
import { useNavigate } from "react-router-dom";
import AdminTitle from "./AdminTitle";
import AgencyLogo from "./AgencyLogo";
import { agencyLinkList } from "../../services/agency-link.service";

const MyLogoutButton = forwardRef((props, ref) => {
  const { signout } = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    await signout();
    navigate("/login");
  };
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  );
});

const MyUserMenu = () => {
  return (
    <UserMenu>
      <MyLogoutButton />
    </UserMenu>
  );
};

const GarityAppBar = () => {
  const { user, isAdmin } = useAuth();
  const { agency } = useAgency();
  const [linkList, setLinkList] = React.useState([]);
  const [linkListAnchorElm, setLinkListAnchorElm] = React.useState(null);
  const selectLinkOpen = Boolean(linkListAnchorElm);
  const handleSelectLinkClick = (event) => {
    setLinkListAnchorElm(event.currentTarget);
  };

  const copyAgencyURL = (href) => {
    if (!navigator.clipboard) {
      alert("Your browser does not allow copying to the clipboard. The link will be opened in a new window for you to copy.");
      window.open(href, "_blank");
      setLinkListAnchorElm(null);
      return;
    }
    navigator.clipboard.writeText(href);
    setLinkListAnchorElm(null);
  };

  React.useEffect(() => {
    async function fetchLinkList() {
      if(isAdmin(user)) setLinkList(await agencyLinkList(agency));
    }
    fetchLinkList();
  }, [agency]);

  return (
    <AppBar userMenu={<MyUserMenu />}>
      <TitlePortal />
      <Box flex="1" />
      <AdminTitle />
      <Box flex="1" />
      {!!linkList?.length && (
        <Box>
          <Button
            color="inherit"
            id="select-link-button"
            aria-controls={selectLinkOpen ? "select-link-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={selectLinkOpen ? "true" : undefined}
            onClick={handleSelectLinkClick}
          >
            <ClipboardIcon /> Copy Agency Link
          </Button>
          <MuiMenu
            id="select-link-menu"
            anchorEl={linkListAnchorElm}
            open={selectLinkOpen}
            onClose={() => setLinkListAnchorElm(null)}
            MenuListProps={{
              "aria-labelledby": "select-link-button",
            }}
          >
            {linkList.map((link) => (
              <MenuItem
                key={link.href}
                onClick={() => copyAgencyURL(link.href)}
              >
                Upline: {link.upline}
              </MenuItem>
            ))}
          </MuiMenu>
        </Box>
      )}
    </AppBar>
  );
};

const MyMenu = () => {
  const resources = useResourceDefinitions();
  const { user, isGarityAdmin, canSubmit } = useAuth();

  return (
    <Menu>
      <AgencyLogo />
      {Object.keys(resources).map((resource) => (
        <Menu.ResourceItem key={resource} name={resource} />
      ))}
      {isGarityAdmin(user) && (
        <Menu.Item
          to="/admin/agent/import"
          primaryText="Agent Import"
          leftIcon={<AddIcon />}
        />
      )}
      <Menu.Item
        to="/contract"
        target="_blank"
        primaryText={
          <Button variant="contained" startIcon={<AddIcon />} fullWidth>
            New PIF Contract
          </Button>
        }
      />
      {canSubmit(user) && (
        <Menu.Item
          to="/ga-contract"
          target="_blank"
          sx={{ justifySelf: "stretch" }}
          primaryText={
            <Button variant="contained" startIcon={<AddIcon />} fullWidth>
              New GA Request
            </Button>
          }
        />
      )}
    </Menu>
  );
};

const MyLayout = (props) => (
  <Layout {...props} appBar={GarityAppBar} menu={MyMenu} />
);

export default MyLayout;

import { Typography } from "@mui/material";

const Confirmation = ({ steps, completeStep, user }) => {
  let data = {};
  steps.forEach(step => {
      data = { ...data, ...step.data };
  });

  return (
    <>
      <Typography variant="h6">Thank you for submitting your request! We've sent a copy to your email address.</Typography>
    </>
  )
};

export default Confirmation;
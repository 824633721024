import axios from "axios";
const API_URL = "/api/ga-contract/";

const request = (contract) => {
    return axios
      .post(API_URL, {
        ...contract
      })
      .then((response) => {
        return response.data;
      });
  };

export default { request };

import { Grid, Typography } from "@mui/material";
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { contractStatusOptions, paymentLabels } from "../../../lib/constants";
import { UserDisplay } from "../User/Display";
import { ValueField } from "../ValueField";
import { useAuth } from "../../../hooks/useAuth";

const blankAgentText = `
  The agent's information was entered manually, rather
  than being populated from the database by NPN lookup
  at the time the request was entered.`;

const policyEntityOptions = {
  individual: "Individual",
  corporate: "Corporate",
};

const policyCreationOptions = {
  new: "New",
  transfer: "Transfer",
};

// const CarrierStateSelection = ({ scopedFormData, carriersList, getSource }) => {
//   const currentCarrier = carriersList?.find(
//     (c) => c.id === scopedFormData.carrier
//   );
//   if (!currentCarrier || !currentCarrier.states?.length) {
//     return null;
//   }
//   const choices = currentCarrier.states.map((c) => ({ id: c, name: c })) || [
//     { id: scopedFormData.carrierState, name: scopedFormData.carrierState },
//   ];
//   return (
//     <SelectInput
//       source={getSource("carrierState")}
//       label="Carrier State"
//       choices={choices}
//     />
//   );
// };

const ActionToolbar = ({ readonly }) => {
  if (readonly) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

export const ContractRequestEdit = () => {
  // useEffect(() => {
  //   async function fetchCarrierList() {
  //     const data = await carrierService.list();
  //     setCarriersList(data);
  //   }
  //   fetchCarrierList();
  // }, []);
  const { user, isGarityAdmin, isStandardUser, isAgencyAdmin } = useAuth();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect()

  const onSuccess = (data) => {
    notify(`Changes saved`);
    redirect('/admin/contract-request');
    refresh();
  };

  return (
    <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<ActionToolbar readonly={isStandardUser(user)} />}
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="h6">Agent Info</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ValueField source="npn" label="NPN" />
              </Grid>
              <Grid item xs={4}>
                <ValueField source="firstName" label="First Name" />
              </Grid>
              <Grid item xs={4}>
                <ValueField source="preferredName" label="Preferred Name" />
              </Grid>

              <Grid item xs={4}>
                <ValueField source="lastName" label="Last Name" />
              </Grid>
              <Grid item xs={4}>
                <ValueField source="upline" />
              </Grid>
              <Grid item xs={4}>
                <ValueField source="topOfHierarchy" label="Top of Hierarchy" />
              </Grid>

              <Grid item xs={6}>
                <ValueField source="email" />
              </Grid>
              <Grid item xs={4}>
                <ValueField source="phone" />
              </Grid>
              <Grid item xs={6}>
                <ValueField source="address" />
              </Grid>

              <Grid item xs={4}>
                <ValueField source="city" />
              </Grid>
              <Grid item xs={3}>
                <ValueField source="state" />
              </Grid>
              <Grid item xs={5}>
                <ValueField source="zip" label="ZIP" />
              </Grid>

              <Grid item xs={6}>
                <ValueField source="ssn" label="Social Security Number" />
              </Grid>
              <Grid item xs={6}>
                <ValueField source="dob" label="DOB" />
              </Grid>

              <Grid item xs={6}>
                <ValueField source="commissionEmail" label="Commission Email" />
              </Grid>
              <Grid item xs={6}>
                <ValueField source="enrollmentEmail" label="Enrollment Email" />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Payment Info</Typography>
                <ValueField
                  source="payment"
                  transform={(v) => paymentLabels[v]}
                />
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    if (formData.payment === "me") {
                      return;
                    }
                    if (formData.payment === "corporation") {
                      return (
                        <>
                          <ValueField
                            fullWidth
                            label="Payment Corporation"
                            source="paymentCorporation"
                          />
                          <ValueField
                            fullWidth
                            label="Payment Tax ID"
                            source="paymentTaxId"
                          />
                        </>
                      );
                    }
                    if (formData.payment === "upline") {
                      return (
                        <ValueField
                          fullWidth
                          label="Payment Upline"
                          source="paymentUpline"
                        />
                      );
                    }
                  }}
                </FormDataConsumer>
              </Grid>

              {/* Agencies can only see their own contract requests;
              Garity should be able to see to whom it belongs */}
              {!isAgencyAdmin(user) && (
                <Grid item xs={12}>
                  <Typography variant="h6">PURL</Typography>
                  {isStandardUser(user) && (
                    <ReferenceField
                      source="agency"
                      reference="agency"
                      emptyText="There is no PURL associated with this PIF"
                    />
                  )}
                  {isGarityAdmin(user) && (
                    <ReferenceInput source="agency" reference="agency">
                      <SelectInput fullWidth={true} optionText="name" />
                    </ReferenceInput>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="h6">Dates</Typography>
                <ValueField
                  source="createdAt"
                  label="Submitted"
                  transform={(v) => new Date(v).toLocaleString("en-US", "short")}
                />
                <ValueField
                  source="updatedAt"
                  label="Last changed"
                  transform={(v) => new Date(v).toLocaleString("en-US", "short")}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Carrier Contracts</Typography>
                <ArrayInput source="contracts">
                  <SimpleFormIterator
                    inline
                    disableAdd={true}
                    disableClear={true}
                    disableReordering={true}
                    disableRemove={true}
                    fullWidth
                  >
                    <FormDataConsumer>
                      {({
                        formData, // The whole form data
                        scopedFormData, // The data for this item of the ArrayInput
                        getSource, // A function to get the valid source inside an ArrayInput
                        ...rest
                      }) => (
                        <>
                          <ReferenceField
                            source={getSource("carrier")}
                            reference="carrier"
                            sx={{ width: "35%" }}
                          >
                            <ValueField source="value" label="Name" />
                          </ReferenceField>
                          <ValueField
                            value={scopedFormData.carrierStates?.join(',')}
                            label="State"
                            sx={{ width: "10%" }}
                          />
                          <ValueField
                            sx={{ width: "15%" }}
                            value={
                              policyCreationOptions[
                                scopedFormData.policyCreation
                              ]
                            }
                            label="New/Transfer"
                          />
                          <ValueField
                            sx={{ width: "15%" }}
                            value={
                              policyEntityOptions[scopedFormData.policyEntity]
                            }
                            label="Individual/Corp"
                          />
                          <SelectInput
                            sx={{ width: "15%" }}
                            source={getSource("status")}
                            fullwidth="true"
                            choices={contractStatusOptions}
                            disabled={!isGarityAdmin(user)}
                          />
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item xs={12}>
                <Typography fullwidth="true" variant="h6">
                  Status
                </Typography>

                <SelectField
                  source="status"
                  fullwidth="true"
                  choices={contractStatusOptions}
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="notes"
                  fullWidth
                  multiline
                  rows={5}
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Contract Specialist (COB)</Typography>
                {!isGarityAdmin(user) ? (
                  <ReferenceField source="specialist" reference="user" />
                ) : (
                  <ReferenceInput sort={{field: "lastName", order: "ASC"}} source="specialist" reference="user" />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Agent</Typography>
                <ReferenceField
                  source="agent"
                  reference="agent"
                  emptyText={blankAgentText}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Submitter</Typography>
                <UserDisplay />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Email Recipients</Typography>
                <Typography variant="caption">
                  <FunctionField
                    render={(record) =>
                      record.emailRecipients?.length
                        ? "The following email addresses were notified when the PIF was submitted"
                        : "No users were notified when the PIF was submitted"
                    }
                  />
                </Typography>
                <Typography>
                  <FunctionField
                    render={(record) =>
                      record.emailRecipients.sort().join(", ")
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default ContractRequestEdit;

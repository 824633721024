import { Edit } from 'react-admin';

import AgencyLinkFields from './Fields';
import { AgencyLinkTitle } from '.';


export const AgencyLinkEdit = (props) => (
    <Edit title={<AgencyLinkTitle />} redirect="list" {...props}>
        <AgencyLinkFields />
    </Edit>
);

export default AgencyLinkEdit;

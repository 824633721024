import {
  Article,
  HealthAndSafety,
  NoteAdd,
  Person,
  Warehouse,
  Link,
} from "@mui/icons-material";
import { useEffect } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import dataProvider from "../../lib/dataProvider";
import { AgencyCreate, AgencyEdit, AgencyList } from "./Agency";
import { AgentImport, AgentList } from "./Agent";
import { CarrierCreate, CarrierEdit, CarrierList } from "./Carrier";
import {
  ContractRequestEdit,
  ContractRequestLabel,
  ContractRequestList,
} from "./ContractRequest";
import { GAContractEdit, GAContractLabel, GAContractList } from "./GAContract";
import { AgencyLinkCreate, AgencyLinkEdit, AgencyLinkList } from "./AgencyLink";
import MyLayout from "./Layout";
import { UserCreate, UserEdit, UserList } from "./User";

const AgencyAdmin = () => {
  return (
    <Admin basename="/admin" layout={MyLayout} dataProvider={dataProvider}>
      <Resource
        name="contract-request"
        list={ContractRequestList}
        edit={ContractRequestEdit}
        icon={NoteAdd}
        options={{ label: ContractRequestLabel }}
      />
      <Resource
        name="ga-contract"
        list={GAContractList}
        edit={GAContractEdit}
        icon={Article}
        options={{ label: GAContractLabel }}
      />
      <Resource
        name="agency"
        options={{ label: "PURL Agency Info" }}
        edit={AgencyEdit}
        list={AgencyList}
        icon={Warehouse}
        recordRepresentation={(record) => record.name}
      />
      <Resource
        options={{ label: "Agency Links" }}
        name="agency-link"
        create={AgencyLinkCreate}
        edit={AgencyLinkEdit}
        list={AgencyLinkList}
        icon={Link}
        recordRepresentation={(record) => record.upline}
      />
      <Resource
        name="carrier"
        list={CarrierList}
        edit={CarrierEdit}
        icon={HealthAndSafety}
      />
      <Resource
        name="user"
        edit={UserEdit}
        list={UserList}
        icon={Person}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName} (${record.email})`
        }
      />
    </Admin>
  );
};

const GarityAdmin = () => {
  return (
    <Admin basename="/admin" layout={MyLayout} dataProvider={dataProvider}>
      <Resource
        name="contract-request"
        list={ContractRequestList}
        edit={ContractRequestEdit}
        icon={NoteAdd}
        options={{ label: ContractRequestLabel }}
      />
      <Resource
        name="ga-contract"
        list={GAContractList}
        edit={GAContractEdit}
        icon={Article}
        options={{ label: GAContractLabel }}
      />
      <Resource
        name="user"
        create={UserCreate}
        edit={UserEdit}
        list={UserList}
        icon={Person}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName} (${record.email})`
        }
      />
      <Resource
        name="agent"
        list={AgentList}
        icon={Person}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName} (${record.email})`
        }
      />
      <Resource
        options={{ label: "PURL Agency Info" }}
        name="agency"
        create={AgencyCreate}
        edit={AgencyEdit}
        list={AgencyList}
        icon={Warehouse}
        recordRepresentation={(record) => record.name}
      />
      <Resource
        options={{ label: "Agency Links" }}
        name="agency-link"
        create={AgencyLinkCreate}
        edit={AgencyLinkEdit}
        list={AgencyLinkList}
        icon={Link}
        recordRepresentation={(record) => record.upline}
      />
      <Resource
        name="carrier"
        list={CarrierList}
        create={CarrierCreate}
        edit={CarrierEdit}
        icon={HealthAndSafety}
      />
      <CustomRoutes>
        <Route path="/agent/import" element={<AgentImport />} />
      </CustomRoutes>
    </Admin>
  );
};

const GarityStandard = () => {
  return (
    <Admin basename="/admin" layout={MyLayout} dataProvider={dataProvider}>
      <Resource
        name="contract-request"
        list={ContractRequestList}
        edit={ContractRequestEdit}
        icon={NoteAdd}
        options={{ label: ContractRequestLabel }}
      />
      <Resource
        name="ga-contract"
        list={GAContractList}
        edit={GAContractEdit}
        icon={Article}
        options={{ label: GAContractLabel }}
      />
      <Resource
        name="user"
        list={UserList}
        edit={UserEdit}
        icon={Person}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName} (${record.email})`
        }
      />
      <Resource
        name="agent"
        list={AgentList}
        icon={Person}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName} (${record.email})`
        }
      />
      <Resource
        options={{ label: "PURL Agency Info" }}
        name="agency"
        list={AgencyList}
        edit={AgencyEdit}
        icon={Warehouse}
        recordRepresentation={(record) => record.name}
      />
      <Resource
        name="carrier"
        list={CarrierList}
        edit={CarrierEdit}
        icon={HealthAndSafety}
      />
    </Admin>
  );
};

const AdminComponent = () => {
  const { user, isStandardUser, isGarityAdmin, isAgencyAdmin, loading } =
    useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    // Authentication is required.
    if (!user) {
      navigate("/login");
      return;
    }
  }, [user, loading]);

  if (loading) return <>Loading</>;

  if (!user) return <></>;

  if (isAgencyAdmin(user)) return <AgencyAdmin />;
  if (isGarityAdmin(user)) return <GarityAdmin />;
  if (isStandardUser(user)) return <GarityStandard />;
};

export default AdminComponent;

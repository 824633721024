import {
  email,
  SimpleForm,
  ImageInput,
  TextInput,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";

const validateEmail = email();

const hasCustomDomain = (formData) => !!formData.domain?.length;

const AgencyFields = () => {
  const { user, isGarityAdmin, isStandardUser } = useAuth();
  return (
    <SimpleForm>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  isRequired
                  source="name"
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  source="contactFirstName"
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  source="contactLastName"
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  validate={validateEmail}
                  source="contactEmail"
                  disabled={isStandardUser(user)}
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  source="notifyContractRequestEmail"
                  label="Notification email for Contract Requests"
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  source="notifyGARequestEmail"
                  label="Notification email for GA Requests"
                  disabled={isStandardUser(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  reference="user"
                  source="defaultPIFSpecialist"
                  disabled={isStandardUser(user)}
                >
                  <AutocompleteInput label="Default PIF COB" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  reference="user"
                  source="defaultGASpecialist"
                  disabled={isStandardUser(user)}
                >
                  <AutocompleteInput label="Default GA COB" />
                </ReferenceInput>
              </Grid>

              {(isGarityAdmin(user) || isStandardUser(user)) && (
                <Grid item xs={12}>
                  <ReferenceArrayInput source="carriers" reference="carrier">
                    <AutocompleteArrayInput
                      optionText={(c) => c.value}
                      disabled={isStandardUser(user)}
                    />
                  </ReferenceArrayInput>
                </Grid>
              )}

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch checked={hasCustomDomain(formData)} disabled />
                  }
                  label="Custom Domain Enabled"
                />

                {(isGarityAdmin(user) || isStandardUser(user)) && (
                  <TextInput
                    fullWidth
                    label="Domain"
                    source="domain"
                    disabled={isStandardUser(user)}
                  />
                )}
              </Grid>

              <Grid item xs={6} style={{ textAlign: "center" }}>
                <ImageInput
                  source="logo"
                  accept={[".jpg", ".jpeg", ".png", ".webp"].join(",")}
                  disabled={isStandardUser(user)}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
            </Grid>
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default AgencyFields;

import { Create } from 'react-admin';
import AgencyLinkFields from './Fields';

export const AgencyLinkCreate = () => (
    <Create redirect="list">
        <AgencyLinkFields />
    </Create>
);

export default AgencyLinkCreate;

import { Routes, Route } from "react-router-dom";
import ContractRequest from "./routes/ContractRequest";
import Forgot from "./routes/Forgot";
import Login from "./routes/Login";
import Reset from "./routes/Reset";
import Invite from "./routes/Invite";
import Admin from "./routes/Admin";
import Home from "./routes/Home";
import GAContract from "./routes/GAContract";
import configureAxios from "./lib/configAxios";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import * as Sentry from "@sentry/react";

import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

dayjs.extend(customParseFormat);
configureAxios(axios);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SentryRoutes>
            <Route path="/" element={<Home />} />
            <Route path="/contract" element={<ContractRequest />} />
            <Route path="/ga-contract" element={<GAContract />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/invite" element={<Invite />} />
          </SentryRoutes>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { statesList, roleOptions } from "../../../lib/constants";
import { useAuth } from "../../../hooks/useAuth";
import { Typography, Grid } from "@mui/material";



const AgencyInput = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.role !== "agency") {
          return;
        }
        return (
          <>
            <ReferenceInput source="agency" reference="agency">
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const NotificationControls = ({ readonly }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.role !== "admin") {
          return;
        }
        return (
          <>
            <BooleanInput
              label="Notify of new contract requests for all states"
              source="notifyAllContracts"
              disabled={readonly}
            />
            {!formData.notifyAllContracts && (
              <SelectArrayInput
                label="Notify of Contract Requests for these states"
                source="notifyContractStates"
                style={{ width: "90%" }}
                choices={statesList}
                disabled={readonly}
              />
            )}
            <BooleanInput
              label="Notify for new GA Contract requests"
              source="notifyGAContract"
              disabled={readonly}
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const ActionToolbar = ({ readonly }) => {
  if (readonly) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );
};

export const UserEdit = () => {
  const { user, isGarityAdmin } = useAuth();

  return (
    <Edit redirect="list">
      <SimpleForm warnWhenUnsavedChanges  toolbar={<ActionToolbar readonly={!isGarityAdmin(user)} />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  source="firstName"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="lastName"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="preferredName"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="email"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput
                  disabled={!isGarityAdmin(user)}
                  source="role"
                  choices={roleOptions}
                />
              </Grid>

              {isGarityAdmin(user) && (
                <Grid item xs={6}>
                  <AgencyInput />
                </Grid>
              )}
            </Grid>
            <NotificationControls readonly={!isGarityAdmin(user)} />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source="address"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="city"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  source="state"
                  choices={statesList}
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="zip" disabled={!isGarityAdmin(user)} />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  source="phone"
                  fullWidth
                  disabled={!isGarityAdmin(user)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
export default UserEdit;

import { Grid, Stack, Typography, Box } from "@mui/material";
import {
  ArrayInput,
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  Labeled,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import currency from "currency.js";
import {
  contractStatusOptions,
  gaApprovalOptions,
  gaCarrierPaymentUnitOptions,
  gaStatusOptions,
} from "../../../lib/constants";
import { capitalize } from "inflected";
import { UserDisplay } from "../User/Display";
import { ValueField } from "../ValueField";
import { useAuth } from "../../../hooks/useAuth";

const formatAmount = (value) => {
  return `${value}%`;
}

const formatMoney = (value) => currency(value).format();
const formatDate = (value) =>
  new Date(value).toLocaleDateString("en-US", { timeZone: "UTC" });

const ActionToolbar = ({ readonly }) => {
  if (readonly) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

export const GAContractEdit = () => {
  const { user, isGarityAdmin, isStandardUser, isAgencyAdmin } = useAuth();

  return (
    <Edit>
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<ActionToolbar readonly={!isGarityAdmin(user)} />}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">Agent Info</Typography>
            <ReferenceField reference="agent" source="ga">
              <ValueField
                label="Name"
                transform={(agent) =>
                  `${agent.firstName} ${agent.lastName} (${agent.email})`
                }
              />
            </ReferenceField>
            <Typography variant="h6" style={{ marginTop: 16 }}>
              Agent when submitted
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Labeled label="First name">
                  <TextField source="agentSnapshot.firstName" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Last name/Name of Company">
                  <TextField source="agentSnapshot.lastName" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="NPN">
                  <TextField source="agentSnapshot.npn" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Advisor">
                  <TextField source="agentSnapshot.advisor" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Email Address">
                  <TextField source="agentSnapshot.email" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Phone">
                  <TextField source="agentSnapshot.phone" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Address: Street">
                  <TextField source="agentSnapshot.addressStreet" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Address: State">
                  <TextField source="agentSnapshot.addressState" />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled label="Top of Hierarchy">
                  <TextField source="agentSnapshot.topOfHierarchy" />
                </Labeled>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="h6">Status</Typography>

                <SelectInput
                  source="status"
                  disabled={!isGarityAdmin(user)}
                  fullwidth="true"
                  choices={contractStatusOptions}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">Approval</Typography>

                <SelectInput
                  source="approval"
                  disabled={!isGarityAdmin(user)}
                  fullwidth="true"
                  choices={gaApprovalOptions}
                />

                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    if (
                      !formData.approvalChangedBy ||
                      formData.approval === "pending"
                    ) {
                      return;
                    }
                    return (
                      <>
                        <br />
                        <b>{capitalize(formData.approval)} by</b>
                        <br />
                        <ReferenceField
                          source="approvalChangedBy"
                          reference="user"
                        />{" "}
                        on {formatDate(formData.approvalChangedAt)}
                      </>
                    );
                  }}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Submitter</Typography>
                <UserDisplay />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography variant="h6">Contract Specialist (COB)</Typography>
                {!isGarityAdmin(user) ? (
                  <ReferenceField source="specialist" reference="user" />
                ) : (
                  <ReferenceInput sort={{field: "lastName", order: "ASC"}}  source="specialist" reference="user" />
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6">Dates</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <ValueField
                      source="createdAt"
                      label="Submitted"
                      transform={(v) => new Date(v).toLocaleString("en-US", "short")}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <ValueField
                      source="updatedAt"
                      label="Last changed"
                      transform={(v) => new Date(v).toLocaleString("en-US", "short")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Agencies can only see their own contract requests;
              Garity should be able to see to whom it belongs */}
            {!isAgencyAdmin(user) && (
              <Grid item xs={12} sx={{marginTop: -2}}>
                {isStandardUser(user) && (
                  <ReferenceField
                    source="agency"
                    reference="agency"
                    label="PURL"
                    emptyText="There is no PURL associated with this GA Request"
                  />
                )}
                {isGarityAdmin(user) && (
                  <ReferenceInput source="agency" reference="agency">
                    <SelectInput label="PURL" fullWidth={true} optionText="name" />
                  </ReferenceInput>
                )}
              </Grid>
            )}
            <Grid xs={12} sx={{mt: -1}}>
              <TextInput
                source="notes"
                fullWidth
                multiline
                rows={3}
                disabled={!isGarityAdmin(user)}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ marginInline: 2 }}>
            <Grid xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Carrier Info
              </Typography>

              <ArrayInput source="carriers">
                <SimpleFormIterator
                  inline
                  disableAdd={true}
                  disableClear={true}
                  disableReordering={true}
                  disableRemove={true}
                  fullWidth
                  sx={{
                    [`& .RaSimpleFormIterator-line`]: {
                      borderBottom: "none",
                    }
                  }}
                >
                  <FormDataConsumer>
                    {({
                      formData, // The whole form data
                      scopedFormData, // The data for this item of the ArrayInput
                      getSource, // A function to get the valid source inside an ArrayInput
                      ...rest
                    }) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "self-start",
                            width: "100%",
                            gap: 1,
                          }}
                        >
                          <ReferenceField
                            source={getSource("carrier")}
                            reference="carrier"
                            sx={{ width: "35%" }}
                          >
                            <ValueField
                              source="value"
                              label="Name"
                              sx={{ width: "100%" }}
                            />
                          </ReferenceField>

                          {scopedFormData.newPolicyUnit == "dollar" && (
                            <ValueField
                              sx={{ width: "90%" }}
                              value={scopedFormData.newPolicyAmount}
                              label="New Policy Amount"
                              transform={formatMoney}
                            />
                          )}
                          {scopedFormData.newPolicyUnit == "percent" && (
                            <ValueField
                              sx={{ width: "90%" }}
                              value={scopedFormData.newPolicyAmount}
                              label="New Policy Amount"
                              transform={formatAmount}
                            />
                          )}

                          {scopedFormData.renewalUnit == "dollar" && (
                            <ValueField
                              sx={{ width: "90%" }}
                              value={scopedFormData.renewalAmount}
                              label="Renewal Amount"
                              transform={formatMoney}
                            />
                          )}
                          {scopedFormData.renewalUnit == "percent" && (
                            <ValueField
                              sx={{ width: "90%" }}
                              value={scopedFormData.renewalAmount}
                              label="Renewal Amount"
                              transform={formatAmount}
                            />
                          )}

                          <ValueField
                            sx={{ width: "13%" }}
                            value={scopedFormData.startDate}
                            label="Start Date"
                            transform={formatDate}
                          />
                          <ValueField
                            value={scopedFormData.hasLOAAgents}
                            label="Has LOA Agents?"
                            transform={(v) => (v ? "Yes" : "No")}
                            sx={{ width: "20%" }}
                          />
                          <TextInput
                            sx={{ width: "20%" }}
                            source={getSource("level")}
                            label="Level"
                            helperText={false}
                            disabled={!isGarityAdmin(user)}
                          />
                          <SelectInput
                            source={getSource("status")}
                            disabled={!isGarityAdmin(user)}
                            sx={{ width: "20%" }}
                            helperText={false}
                            choices={gaStatusOptions}
                          />
                          <ReferenceInput
                            source={getSource("processor")}
                            reference="user"
                          >
                            <AutocompleteInput
                              sx={{ width: "35%" }}
                              disabled={!isGarityAdmin(user)}
                              helperText={false}
                              label="Processor"
                            />
                          </ReferenceInput>
                        </Box>
                      </>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
            <Grid xs={12}>
              <Typography variant="h6">Email Recipients</Typography>
              <Typography variant="caption">
                <FunctionField
                  render={(record) =>
                    record.emailRecipients?.length
                      ? "The following email addresses were notified when the GA Request was submitted"
                      : "No users were notified when the GA Request was submitted"
                  }
                />
              </Typography>
              <Typography>
                <FunctionField
                  render={(record) => record.emailRecipients.sort().join(", ")}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default GAContractEdit;

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        text:{
          primary: "#333"
        }
    }
  });

export default theme;
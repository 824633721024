import React from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Button,
  Snackbar,
  Alert,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MuiFileInput } from "mui-file-input";
import agentService from "../../../services/agent.service";

const AgentImport = () => {
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleChange = (newFile) => {
    setFile(newFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await agentService.importAgents(formData);
      if (response.status === 201) {
        const { imported, total } = response.data;
        const failed = total - imported;
        setSuccessMessage(
          `Agents imported: ${total} records, ${imported} imported, ${failed} failed`
        );
      } else {
        setErrorMessage(`Something went wrong: ${response.data}`);
      }
    } catch (error) {
      setErrorMessage("Something went wrong: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (_evt, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessMessage(null);
    setErrorMessage(null);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Container maxWidth="md">
        <Box sx={{ padding: 3, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            Select agent spreadsheet from SmartOffice
          </Typography>
          <div>
            <MuiFileInput
              value={file}
              onChange={handleChange}
              variant="filled"
            />
          </div>
          <div>
            <Button
              sx={{ marginTop: "1rem" }}
              onClick={handleSubmit}
              type="submit"
              disabled={loading || !file}
              variant="contained"
            >
              Submit agent spreadsheet
            </Button>
          </div>
          <div>
            {loading && (
              <LinearProgress sx={{ marginTop: "2rem" }} thickness={8} />
            )}
          </div>
        </Box>
      </Container>
      {successMessage && (
        <Snackbar
          open={!!successMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          open={!!errorMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
          action={action}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AgentImport;

import React from 'react';
import Header from '../Header';
import { Box, Paper, Typography } from '@mui/material';

const page = {
    background: '#1d3d7c',
    minHeight: '100vh',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
}

const Layout = ({ children }) => {

    return (
        <Box sx={page}>
            <Box sx={{ padding: 2 }}>
                <Paper elevation={3} sx={{ margin: '0 auto', maxWidth: 1280, padding: 2, paddingBottom: 4 }}>
                    <Header />
                    {children}
                </Paper>
            </Box>
            <Box sx={{ margin: '0 auto', maxWidth: 900, fontSize: '.8rem', paddingBottom: 4, color: '#fff' }}>
                <Typography variant='body2' component={'span'}>
                    Call with 800-234-9488 or email onboarding@garityadvantage.com with questions
                </Typography>
            </Box>
        </Box>)
};

export default Layout;

import {
  Datagrid,
  TopToolbar,
  CreateButton,
  ExportButton,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import { useAuth } from "../../../hooks/useAuth";

const ListActions = ({ isGarityAdmin }) => (
  <TopToolbar>
    {isGarityAdmin && <CreateButton label="Invite New User" />}
    <ExportButton />
  </TopToolbar>
);

export const UserList = () => {
  const { user, isGarityAdmin } = useAuth();
  return (
    <List actions={<ListActions isGarityAdmin={isGarityAdmin(user)} />}>
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ReferenceField source="agency" reference="agency">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default UserList;

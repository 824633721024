import * as Sentry from "@sentry/react";
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { ProvideAuth } from "./hooks/useAuth";
import { ProvideAgencyLinkData } from "./hooks/useAgencyLink";
import { ProvideAgency } from "./hooks/useAgency";

// https://143ab2b1a5bc4afeb8fc789ecf794adb@o30120.ingest.sentry.io/4505580075941888"
const sentryDSN = process.env.REACT_APP_SENTRY_DSN || "";

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/.*/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProvideAuth>
      <BrowserRouter>
        <ProvideAgency>
          <ProvideAgencyLinkData>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ProvideAgencyLinkData>
        </ProvideAgency>
      </BrowserRouter>
    </ProvideAuth>
  </React.StrictMode>
);

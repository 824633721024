import CarrierList from './List';
import CarrierEdit from './Edit';
import CarrierCreate from './Create';
import { useRecordContext } from 'react-admin';

const CarrierTitle = () => {
  const record = useRecordContext();

  return (
      <>
          {record?.value ? `${record.value}` : 'Untitled Carrier' }
      </>
  )
}


export { CarrierList, CarrierEdit, CarrierCreate, CarrierTitle };

import { Datagrid, List, TextField } from "react-admin";

export const CarrierList = () => {
  return (
    <List sort={{ field: "value", order: "ASC" }}>
      <Datagrid rowClick="edit">
        <TextField source="value" />
        <TextField source="email" label="Contact" />
        <TextField source="productType" />
      </Datagrid>
    </List>
  );
};

export default CarrierList;

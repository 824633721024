import axios from "axios";
const API_URL = "/api/carrier/";

const list = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const carrierService = { list };

export default carrierService;

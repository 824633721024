import { useAgency } from "../../hooks/useAgency";

export default function AgencyLogo() {
  const { agency } = useAgency();

  if (!agency || agency.isDefault) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 20,
      }}
    >
      <img src={agency.logo.src} style={{
        width: 200,
      }} alt="logo" />
    </div>
  );
}

import React from 'react';
import Layout from '../components/Layout';
import { Box} from '@mui/material';
import GAContract from '../components/GAContract';

const GAContractRequest = () => {
    return (
        <Layout>
            <Box sx={{ padding: 4 }}>
                <GAContract />
            </Box>
        </Layout>
    );
};

export default GAContractRequest;

import { Create } from "react-admin";
import { useAuth } from "../../../hooks/useAuth";
import CarrierFields from "./Fields";

export const CarrierCreate = () => {
  const { user, isAgencyAdmin, isGarityAdmin } = useAuth();

  return (
    <Create redirect="list">
      <CarrierFields
        user={user}
        isAgencyAdmin={isAgencyAdmin}
        isGarityAdmin={isGarityAdmin}
      />
    </Create>
  );
};
export default CarrierCreate;

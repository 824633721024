import { Datagrid, List, TextField, ReferenceField } from "react-admin";

export const AgencyLinkList = () => (
  <List>
    <Datagrid rowClick="edit">
      <ReferenceField source="agency" reference="agency" link={false} />
      <TextField source="upline" />
    </Datagrid>
  </List>
);

export default AgencyLinkList;

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { Field, useFormikContext } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { MoneyInput } from "../FormInput";

import { Box } from "@mui/system";
import { FieldArray } from "formik";
import { gaCarrierPaymentUnitOptions } from "../../lib/constants";
import SelectInput from "../SelectInput";

const InputStyle = {
  marginBottom: 2,
};

const defaultStartDate = (() => {
  // The following is the "busy season" logic.
  // If we're in the busy season, the start date is the first day of February.
  // It's temporarily disabled per Tony's request.
  // const today = dayjs();
  // This looks wrong, but it's right. Months are zero indexed.
  // const february = 1;
  // const october = 9;
  // const november = 10;
  // const december = 11;
  // const january = 0;
  // const busySeason = [october, november, december, january];
  // const inBusySeason = busySeason.includes(today.month());
  // if (inBusySeason) {
  //   const februaryMonth = today.month(february).startOf("month");
  //   const februaryFull = today.month() !== january ? februaryMonth.add(1, "year") : februaryMonth;
  //   return februaryFull;
  // }
  // otherwise, first day of next month
  return dayjs().startOf("month").add(1, "month");
})();

const dateChoices = (() => {
  // first day of next month
  const dates = _.range(12).map((i) => defaultStartDate.add(i, "month"));
  return dates;
})();

const CarrierSelection = ({ carrierList }) => {
  const formikContext = useFormikContext();
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const { carriers } = formikContext.values;

  const unselectedCarriers = carrierList.filter((carrier) => {
    return !carriers.some((c) => c.id === carrier.id);
  });

  if (!carrierList) return <></>;

  return (
    <>
      <FieldArray name="carriers">
        {({ push, remove }) => {
          return (
            <>
              {carriers.length > 0 && (
                <TableContainer sx={{ marginBottom: 3 }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="Carrier selection">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 210 }}>Carrier</TableCell>
                        <TableCell align="right">New Policy Amount</TableCell>
                        <TableCell align="right">
                          Contract Renewal Amount
                        </TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Will have LOA Agents?</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {carriers.map((row, index) => {
                        let carrier = carrierList.find((c) => c.id === row.id);
                        return (
                          <TableRow
                            key={carrier.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {carrier.value}
                            </TableCell>
                            <TableCell>
                              <div style={{display: "flex"}}>
                                <Field
                                  width="10%"
                                  name={`carriers[${index}].newPolicyUnit`}
                                  component={SelectInput}
                                  options={gaCarrierPaymentUnitOptions}
                                  />
                                <Field
                                  width="90%"
                                  name={`carriers[${index}].newPolicyAmount`}
                                  component={MoneyInput}
                                  variant="outlined"
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                            <div style={{display: "flex"}}>
                                <Field
                                  width="10%"
                                  name={`carriers[${index}].renewalUnit`}
                                  component={SelectInput}
                                  options={gaCarrierPaymentUnitOptions}
                                  />
                                <Field
                                  width="90%"
                                  name={`carriers[${index}].renewalAmount`}
                                  component={MoneyInput}
                                  variant="outlined"
                                />

                              </div>
                            </TableCell>
                            <TableCell>
                              <Box sx={InputStyle}>
                                <Select
                                  name={`carriers[${index}].startDate`}
                                  onChange={formikContext.handleChange}
                                  defaultValue={defaultStartDate.format(
                                    `YYYY-MM-DD`
                                  )}
                                >
                                  {dateChoices.map((date) => (
                                    <MenuItem
                                      value={date.format(`YYYY-MM-DD`)}
                                      key={+date}
                                    >
                                      {date.format("MMMM D, YYYY")}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                name={`carriers[${index}].hasLOAAgents`}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  formikContext.setFieldValue(`carriers[${index}].hasLOAAgents`, checked);
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <FormControl fullWidth>
                <Box
                  sx={{
                    width: "100%",
                    marginBottom: 3,
                    bgcolor: "background.paper",
                  }}
                  component={Paper}
                >
                  <Autocomplete
                    disablePortal
                    fullWidth={true}
                    onChange={(event, carrier) => {
                      if (!carrier) {
                        console.warn("no carrier selected");
                        return;
                      }
                      push({
                        id: carrier.id,
                        value: carrier.value,
                        startDate: defaultStartDate.format(`YYYY-MM-DD`),
                        newPolicyUnit: "dollar",
                        newPolicyAmount: 0,
                        renewalUnit: "dollar",
                        renewalAmount: 0,
                      });
                      setInputValue("");
                      setValue(null);
                    }}
                    inputValue={inputValue}
                    value={value}
                    getOptionLabel={(option) => option.value}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        setInputValue("");
                        return;
                      }
                      if (reason === "clear") {
                        setInputValue("");
                        return;
                      }
                      setInputValue(newInputValue);
                    }}
                    options={unselectedCarriers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth={true}
                        label="Add Carrier"
                      />
                    )}
                  />
                </Box>
              </FormControl>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default CarrierSelection;

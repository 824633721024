import { DateInput, SelectInput, ReferenceInput, AutocompleteInput, BooleanInput, TextInput } from "react-admin";
import {
  contractStatusOptions,
  gaApprovalOptions,
} from "../../../lib/constants";

const gaRequestFilters = [
  <ReferenceInput source="ga" label="GA" reference="agent">
    <AutocompleteInput fullwidth="true" label="GA" />
  </ReferenceInput>,
  <TextInput source="name" label="Name" />,
  <ReferenceInput source="specialist" label="COB" reference="user">
    <AutocompleteInput fullwidth />
  </ReferenceInput>,
  <SelectInput
    source="status"
    fullwidth="true"
    choices={contractStatusOptions}
  />,
  <SelectInput
    source="approval"
    fullwidth="true"
    choices={gaApprovalOptions}
  />,
  <DateInput source="createdAt!GTE" label="After" />,
  <DateInput source="createdAt!LTE" label="Before" />,
  <BooleanInput source="unknownAgent" label="Unknown Agent" />,
];

const adminGaRequestFilters = [
  ...gaRequestFilters,
  <ReferenceInput source="agency" label="Agency" reference="agency">
    <SelectInput fullwidth="true" label="Agency" />
  </ReferenceInput>,
];

export const makeFilters = (role) => {
  switch (role) {
    case "admin":
      return [...adminGaRequestFilters];
    case "garity-readonly":
      return [...adminGaRequestFilters];
    case "garity-submitonly":
      return [...adminGaRequestFilters];
    case "agency":
      return [...gaRequestFilters];
    default:
      throw new Error("Invalid role");
  }
};

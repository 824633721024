import React from 'react';
import { useRecordContext } from 'react-admin';

import AgencyLinkEdit from './Edit';
import AgencyLinkList from './List';
import AgencyLinkCreate from './Create';

const AgencyLinkTitle = () => {
    const record = useRecordContext();
    return (
        <>
            {record?.upline ? `Link for ${record.upline}` : 'Untitled link' }
        </>
    )
}

export { AgencyLinkEdit, AgencyLinkList, AgencyLinkCreate, AgencyLinkTitle};

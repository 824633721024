import React from "react";
import { Button } from "@mui/material";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { Formik, Field, Form } from "formik";
import agentService from "../../services/agent.service";

const validationSchema = Yup.object().shape({
  npn: Yup.number()
    .typeError("NPN must be a number")
    .required("NPN is required"),
});

const Npn = ({ data, completeStep, steps }) => {
  async function lookupGA(vals) {
    try {
      const response = await agentService.findByNPN(vals.npn);
      // pass the agent to the next step
      if (!response) {
        return;
      }
      const {
        id: agent,
        addressStreet: address,
        addressState: state,
        addressCity: city,
        addressZip: zip,
        topOfHierarchy,
        phone,
        firstName,
        lastName,
        preferredName,
        email,
      } = response;
      var agentData = {
        agent,
        address,
        state,
        city,
        zip,
        topOfHierarchy,
        phone,
        firstName,
        lastName,
        preferredName,
        email,
      };

      return agentData;
    } catch (err) {
      console.error(err);
    }
  }

  const submit = async (data) => {
    var agentData = await lookupGA(data);
    completeStep({...data, ...agentData});
  };

  return (
    <Formik
      initialValues={{
        npn: data?.npn ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ values, onBlur, onChange }) => (
        <Form>
          <Field
            name="npn"
            label="Individual NPN"
            fullWidth
            component={FormInput}
            variant="outlined"
          />
          <Button type="submit" fullWidth variant="contained">
            Next
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Npn;

const configureAxios = (axiosInstance) => {
  axiosInstance.defaults.withCredentials = true;
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.localStorage.removeItem("user");
      }
      return Promise.reject(error);
    }
  );
};

export default configureAxios;

import { Button, Step, StepButton, Stepper, Typography } from "@mui/material";

import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import AgentInfo from "./AgentInfo";
import Carrier from "./Carrier";
import Confirmation from "./Confirmation";
import Npn from "./Npn";
import Payment from "./Payment";
import Review from "./Review";

var stepList = [
  {
    step: 1,
    label: "NPN",
    component: Npn,
    completed: false,
  },
  {
    step: 2,
    label: "Agent Info",
    component: AgentInfo,
    completed: false
  },
  {
    step: 3,
    label: "Payment",
    component: Payment,
    completed: false
  },
  {
    step: 4,
    label: "Contract Request",
    component: Carrier,
    completed: false
  },
  {
    step: 5,
    label: "Review",
    component: Review,
    completed: false
  },
  {
    step: 6,
    label: "Confirmation",
    component: Confirmation,
    completed: false
  },
];

const Steps = () => {
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(stepList);
  const [data, setData] = useState({});
  const { user } = useAuth();
  const Content = steps[step - 1].component;

  const goBack = () => {
    setStep(step - 1);
  };

  const completeStep = (stepData) => {
    // Update our shared data model
    var newData = {...data, ...stepData};
    setData(newData);

    // Mark current step as complete
    var newSteps = [];
    steps.forEach((s) => {
      if (s.step === step) {
        newSteps.push({ ...s, data: data, completed: true });
      } else {
        newSteps.push({ ...s });
        }
    });

    setSteps(newSteps);

    // Advance the wizard to the next step
    setStep(step + 1);
  };

  return (
    <>
      <Typography
        variant="h4"
        sx={{ marginBottom: 2, marginTop: -4 }}
        textAlign={"center"}
      >
        Contract Request (PIF)
      </Typography>
      <Stepper nonLinear activeStep={step - 1} sx={{ paddingBottom: 4 }}>
        {steps.map((item, index) => {
          if (index === 5) {
            return "";
          }
          return (
            <Step key={item.step} completed={item.completed}>
              {item.completed && step !== 6 ? (
                <StepButton
                  onClick={() => {
                    setStep(item.step);
                  }}
                  color="inherit"
                  sx={{ textDecoration: "underline" }}
                >
                  {item.label}
                </StepButton>
              ) : (
                <StepButton color="inherit">{item.label}</StepButton>
              )}
            </Step>
          );
        })}
      </Stepper>
      <Content
        user={user}
        steps={steps}
        data={data}
        completeStep={completeStep}
      />
      {step > 1 && step <= 5 && (
        <Button sx={{marginTop: '8px'}} variant="contained" color="secondary" onClick={goBack}>
          Back
        </Button>
      )}
    </>
  );
};

export default Steps;

import React from 'react';
import Layout from '../components/Layout';
import { Box} from '@mui/material';
import Home from '../components/Home';

const HomeRoute = () => {
    return (
        <Layout>
            <Box sx={{ padding: 4 }}>
                <Home />
            </Box>
        </Layout>
    );
};

export default HomeRoute;

import React from "react";
import {
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  useEditContext,
  useGetOne,
  useCreatePath,
  Link,
} from "react-admin";
import ClipboardIcon from "@mui/icons-material/Assignment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";

const copyAgencyURL = (href) => {
  if (!navigator.clipboard) {
    alert(
      "Your browser does not allow copying to the clipboard. The link will be opened in a new window for you to copy."
    );
    window.open(href, "_blank");
    return;
  }
  navigator.clipboard.writeText(href);
};

const AgencyLinkFields = () => {
  const { user, isAgencyAdmin } = useAuth();
  const { record } = useEditContext();
  const { data: agency, isLoading: isLoadingAgency } = useGetOne("agency", { id: record?.agency }, { enabled: !!record?.agency });
  const [agencyLink, setAgencyLink] = React.useState(null);
  const [domainWarning, setDomainWarning] = React.useState(false);
  const createPath = useCreatePath();

  React.useEffect(() => {
    if (isLoadingAgency) {
      return;
    }
    // We're currently setting the agency, i.e., creating the agency link for the first time
    if (!agency) {
      setDomainWarning(false);
      return;
    }
    setDomainWarning(!agency?.domain);
  }, [agency, isLoadingAgency]);

  React.useEffect(() => {
    const recordId = record?.id;
    const agencyDomain = agency?.domain;
    if (!(agency && recordId && agencyDomain)) {
      return setAgencyLink(null);
    }
    const u = new URL(window.location.href);
    u.hostname = agencyDomain;
    u.pathname = "";
    u.searchParams.set("agencyLink", recordId);
    setAgencyLink(u.toString());
  }, [agency, record]);

  return (
    <SimpleForm>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isAgencyAdmin(user) ? (
                  <ReferenceField source="agency" reference="agency" />
                ) : (
                  <ReferenceInput source="agency" reference="agency" />
                )}
              </Grid>
              <Grid item xs={6}>
                <TextInput fullWidth isRequired source="upline" />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  source="topOfHierarchy"
                  label="Top of Hierarchy"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {agencyLink && (
                  <>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Link
                    </Typography>

                    <Box sx={{ fontFamily: "monospace", textAlign: "center" }}>
                      {agencyLink}
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                    >
                      <Button
                        variant="contained"
                        sx={{ flexBasis: "30%" }}
                        href={agencyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open in New Tab
                        <OpenInNewIcon />
                      </Button>
                      <Button
                        sx={{ flexBasis: "30%" }}
                        variant="contained"
                        onClick={() => copyAgencyURL(agencyLink)}
                      >
                        <ClipboardIcon /> Copy Link to Clipboard
                      </Button>
                    </Box>
                  </>
                )}

                {domainWarning && (
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    No PURL domain is set for the selected agency, "{agency?.name}".
                    <Link to={createPath({
                      resource: "agency",
                      id: agency?.id,
                      type: "edit",
                    })}>Please set your agency domain in the agency settings</Link>{" "}
                    before creating an agency link.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default AgencyLinkFields;

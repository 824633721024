import { fetchUtils } from "react-admin";
import restServerProvider from "ra-data-json-server";

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Content-Type", "application/json");
  options.credentials = "include";

  try {
    return await fetchUtils.fetchJson(url, options);
  } catch (e) {
    console.error("garity http client:", e.message || e);
    // Getting a 401 error means the user is not logged in.
    // A user object is cached in local storage, and it is possible
    // that under some circumstances that local storage object may
    // be out of sync with the server's interpretation of the user's
    // session cookie (for instance, if the session secret changed).
    // Without this, the UX is very confusing, because the user
    // will see the interface of someone who is logged in but any action,
    // including viewing a page, will result in a bunch of errors.
    if (e.status === 401) {
      localStorage.removeItem("user");
      const redirectTarget = new URL('/login', window.location.href);
      redirectTarget.searchParams.append("fromError", "true");
      window.location.href = redirectTarget;
      return;
    }
    throw e;
  }
};

// op is either "create" or "update"
const saveAgency = async (op, resource, params) => {
  const logo = params.data.logo;
  if (logo?.rawFile) {
    const picture64 = await convertFileToBase64(logo);
    params.data.logo = {
      src: picture64,
      title: `${logo.title || "Logo"}`,
    };
  }
  const writeResult = await dataProvider[op](resource, params);
  return await writeResult;
};

const dataProvider = restServerProvider("/api", httpClient);

const myDataProfider = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource === "agency") {
      return saveAgency("create", resource, params);
    }
    // fallback to the default implementation
    return dataProvider.create(resource, params);
  },
  update: (resource, params) => {
    if (params?.data?.createdAt) {
      delete params.data.createdAt;
    }
    if (resource === "agency") {
      return saveAgency("update", resource, params);
    }
    // fallback to the default implementation
    return dataProvider.update(resource, params);
  },
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export default myDataProfider;

import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import currency from "currency.js";
import InputMask from "react-input-mask";
import { getIn } from "formik";

const InputStyle = {
  marginBottom: 1,
};

const ErrorStyle = {
  marginBottom: 1,
  color: "#d32f2f",
};

const FormInput = ({ field, form, ...props }) => {
  return (
    <Box sx={InputStyle}>
      <TextField
        autoComplete={props.autoComplete}
        sx={InputStyle}
        error={
          getIn(form.touched, field.name) && getIn(form.errors, field.name) ? true : false
        }
        {...field}
        {...props}
      />
      {getIn(form.touched, field.name) && getIn(form.errors, field.name) && (
        <Typography sx={ErrorStyle} variant="body2">
          {getIn(form.errors, field.name)}
        </Typography>
      )}
    </Box>
  );
};

export const MoneyInput = ({ field, form, ...props }) => {
  const [value, setValue] = React.useState(0);
  const [formattedValue, setFormattedValue] = React.useState("");

  const moneyStyle = {
    "& .MuiInputBase-root": {
      "& input": {
        textAlign: "right",
      },
    },
    ...InputStyle
  };

  const handleChange = (e) => {
    const regex = /^[0-9,.\b]+$/;
    if (e.target.value !== "" && !regex.test(e.target.value)) {
      // invalid value
      return;
    }

    const currencyValue = currency(e.target.value);
    setValue(currencyValue.value);
    setFormattedValue(e.target.value);
    form.setFieldValue(field.name, currencyValue.value);
  };

  const handleBlur = (e) => {
    setFormattedValue(currency(value).format({symbol: ""}));
    field.onBlur(e);
  };

  return (
    <Box sx={InputStyle}>
      <TextField
        autoComplete={props.autoComplete}
        sx={moneyStyle}
        error={
          form.touched[field.name] && form.errors[field.name] ? true : false
        }
        {...props}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e)}
        value={value !== 0 ? formattedValue : ""}
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <Typography sx={ErrorStyle} variant="body2">
          {form.errors[field.name]}
        </Typography>
      )}
    </Box>
  );
};

export const DateInput = ({ field, form, ...props }) => {
  return (
    <InputMask mask="99/99/9999" {...field} {...props}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          sx={InputStyle}
          error={
            getIn(form.touched, field.name) && getIn(form.errors, field.name) ? true : false
          }
        />
      )}
    </InputMask>
  );
};

export const SSNInput = ({ field, form, ...props }) => {
  return (
    <InputMask mask="999-99-9999" {...field} {...props}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          sx={InputStyle}
          error={
            getIn(form.touched, field.name) && getIn(form.errors, field.name) ? true : false
          }
        />
      )}
    </InputMask>
  );
};

export default FormInput;

import { Create } from 'react-admin';
import AgencyFields from './Fields';

export const AgencyCreate = () => (
    <Create redirect="list">
        <AgencyFields />
    </Create>
);

export default AgencyCreate;

import axios from "axios";
const API_URL = "/api/auth/";

const forgot = async (email) => {
  const response = await axios.post(API_URL + "forgot", {
    email,
  });
  return response.data;
};

const invite = async (userId, token, password) => {
  const response = await axios.post(API_URL + "reset", {
    userId,
    token,
    password,
  });
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const reset = async (userId, token, password) => {
  const response = await axios.post(API_URL + "reset", {
    userId,
    token,
    password,
  });
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const login = async (email, password) => {
  const response = await axios.post(API_URL + "signin", {
    email,
    password,
  });
  const u = response.data;
  if (!u) {
    return null;
  }
  localStorage.setItem("user", JSON.stringify(response.data));
  console.log("logged in as:", response.data);
  return u;
};

async function logout() {
  localStorage.removeItem("user");
  await axios.delete(API_URL + "signout");
}

const redirectToAgency = async (user) => {
  if (!user?.agency) {
    console.log("redirect to agency: no agency; skipping");
    return false;
  }
  const currentDomain = window.location.hostname;
  const agencyDomain = user.agency?.domain;
  console.log("currentDomain:", currentDomain, "agencyDomain:", agencyDomain);
  if (agencyDomain) {
    if (currentDomain !== agencyDomain) {
      // If they log in to the wrong site, then we need to redirect them to the right one.
      // The thing that makes this tricky is that the origins will be different, so our cached
      // user object will be inaccessible from the correct origin. So we'll quickly log them in to the other site,
      // then redirect them.
      console.log("need to redirect to agency:", agencyDomain);
      const portComponent = window.location.port ? `:${window.location.port}` : "";
      const agencyURLBase = new URL(`${window.location.protocol}//${agencyDomain}${portComponent}`);
      // const agencyLogInURL = new URL(`/login`, agencyURLBase);
      // agencyLogInURL.searchParams.append("fromPurl", "true");
      // window.location.href = agencyLogInURL;
      window.location.href = agencyURLBase;
      return true;
    }
    console.log("not redirecting because user is on correct domain");
    return false;
  }
  console.log(
    "redirect to agency: not redirecting because no agency domain is specified"
  );
  return false;
};

const getCurrentUser = async () => {
  const storedUserJSON = localStorage.getItem("user");
  const user = storedUserJSON && JSON.parse(storedUserJSON);
  if (user && user.expires && user.expires > Date.now()) {
    return Promise.resolve(user);
  }
  try {
    const response = await axios.get(API_URL + "current");
    const u = {
      ...response.data,
      id: response.data._id,
      expires: Date.now() + 1000 * 60 * 60 * 24 * 7, // cache for 1 week
     };
    localStorage.setItem("user", JSON.stringify(u));
    return u;
  } catch (err) {
    localStorage.removeItem("user");
    return null;
  }
};

const checkAuth = () => {
  return getCurrentUser() ? Promise.resolve() : Promise.reject();
};

const AuthService = {
  login,
  logout,
  checkAuth,
  forgot,
  invite,
  reset,
  getCurrentUser,
  redirectToAgency,
};
export default AuthService;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { Formik, Field, Form } from "formik";

import { Button, Alert } from "@mui/material";

import AuthService from "../../services/auth.service";
import { useAuth } from "../../hooks/useAuth";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const messageStyle = {
  marginBottom: 2,
};

const Login = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const { isAdmin, isAgencyAdmin } = useAuth();

  // When the user comes here, it's possible that they were unexpectedly redirected
  // here because the cached User object is out of sync with their session cookie.
  // We can display a message to try to clear up some confusion.
  const [fromError, setFromError] = useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("fromError")) {
      setFromError(true);
    }
  }, []);

  const handleLogin = (data) => {
    setMessage("");
    setLoading(true);

    async function doLogin() {
      try {
        const user = await AuthService.login(data.email, data.password);
        const needsRedirect = await AuthService.redirectToAgency(user);
        if (needsRedirect) {
          console.log("returning after login due to redirect");
          return;
        }
        if (isAdmin(user) || isAgencyAdmin(user)) {
          navigate("/admin");
          window.location.reload();
        } else {
          // Currently this should not be reachable; all users are either admin or agency admins
          // but this has changed in the past, so we'll leave this here for now.
          navigate("/");
          window.location.reload();
        }
      } catch (error) {
        const resMessage =
          error.response?.data?.message || error.message || error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    }
    doLogin();
  };

  return (
    <div className="login-form">
      {fromError && (
        <Alert sx={messageStyle} severity="error">
          Your session has expired. Please log in again.
        </Alert>
      )}
      {message && (
        <Alert sx={messageStyle} severity="error">
          {message}
        </Alert>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleLogin(values);
        }}
      >
        {({ values, onBlur, onChange }) => (
          <Form>
            <Field
              name="email"
              label="E-Mail"
              fullWidth
              component={FormInput}
              variant="outlined"
            />
            <Field
              name="password"
              label="Password"
              type="password"
              fullWidth
              component={FormInput}
              variant="outlined"
            />
            <Button
              type="submit"
              disabled={loading ? true : false}
              fullWidth
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <Button
        type="submit"
        onClick={() => {
          navigate("/forgot");
        }}
        fullWidth
        variant="outline"
      >
        Forgot Password
      </Button>
    </div>
  );
};
export default Login;

import axios from "axios";
const API_URL = "/api/agency-link";

export async function fetchAgencyLinkData(id) {
  if (!id) {
    console.warn("agency link service: fetch link data: no ID");
    return null;
  }
  const response = await axios.get(`${API_URL}/${id}`);
  console.log("agency link service: fetch link data:", response.data);
  return response.data;
}

export async function agencyLinkList(currentAgency) {
  if (!currentAgency?.mainURL) {
    console.warn("agency link service: list: agency has no main URL");
    return [];
  }
  const response = await axios.get(`${API_URL}`, {
    params: { agency: currentAgency.id },
  });
  const transform = (linkData) => {
    const qp = new URLSearchParams();
    qp.append("agencyLink", linkData.id);
    const link = currentAgency.mainURL;
    link.search = qp.toString();
    const href = link.toString();
    return {
      ...linkData,
      href,
    };
  };
  const data = response.data.map(transform);
  console.log("agency link service: list:", data);
  return data;
}

import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";

const InputStyle = {
  marginBottom: 1,
};

const ErrorStyle = {
  marginBottom: 1,
  color: "#d32f2f",
};

const SelectInput = ({ field, form, options, placeholder, ...props }) => {
  return (
    <Box sx={InputStyle}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          data-testid={`select-${props.label}`}
          sx={InputStyle}
          error={
            form.errors[field.name] && form.touched[field.name] ? true : false
          }
          displayEmpty={!!placeholder}
          {...field}
          {...props}
        >
          {placeholder && (
            <MenuItem value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {form.errors[field.name] && form.touched[field.name] && (
          <Typography sx={ErrorStyle} variant="body2">
            {form.errors[field.name]}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

export default SelectInput;

import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SelectArrayInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { productTypeOptions, statesList } from "../../../lib/constants";
import { Grid, Typography } from "@mui/material";

const ActionToolbar = ({ readonly }) => {
  if (readonly) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const CarrierFields = ({ user, isAgencyAdmin, isGarityAdmin }) => (
  <SimpleForm warnWhenUnsavedChanges toolbar={<ActionToolbar readonly={!isGarityAdmin(user)} />}>
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Carrier Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              source="value"
              label="Name"
              fullWidth
              disabled={!isGarityAdmin(user)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              source="productType"
              choices={productTypeOptions}
              disabled={!isGarityAdmin(user)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectArrayInput
              label="States"
              source="states"
              fullWidth
              choices={statesList}
              disabled={!isGarityAdmin(user)}
            />
            <br />
            <Typography variant="caption">
              Carrier requires separate contracts for these states. If the
              submission process is identical for all states in which a carrier
              is active, leave this field blank.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isGarityAdmin(user) && (
        <>
          <Grid item xs={6}>
            <Typography variant="h6">Agencies using this carrier</Typography>
            <ReferenceArrayField
              label="Agencies using this carrier"
              source="agencies"
              reference="agency"
            >
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Grid>
        </>
      )}
    </Grid>
  </SimpleForm>
);

export default CarrierFields;

import React from "react";
import { Box } from "@mui/material";
import { useAgency } from "../../hooks/useAgency";

const Header = ({ children }) => {
  const { agency } = useAgency();

  return (
    <Box sx={{ padding: 3, textAlign: "center" }}>
      {agency && <img alt={agency.name} width="190" src={agency.logo.src} />}
    </Box>
  );
};

export default Header;

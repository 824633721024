import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';
import { Box} from '@mui/material';
import { useAuth } from "../hooks/useAuth";
import ResetForm from '../components/Reset';

const Reset = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user]);
    
    return (
        <Layout>
            <Box sx={{ padding: 4 }}>
                <ResetForm />
            </Box>
        </Layout>
    );
};

export default Reset;
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  FormDataConsumer,
} from "react-admin";
import { statesList, roleOptions } from "../../../lib/constants";
import { Typography, Grid } from "@mui/material";

const AgencyInput = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.role !== "agency") {
          return;
        }
        return (
          <>
            <ReferenceInput source="agency" reference="agency">
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const NotificationControls = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.role !== "admin") {
          return;
        }
        return (
          <>
            <BooleanInput
              label="Notify of new contract requests for all states"
              source="notifyAllContracts"
            />
            {!formData.notifyAllContracts && (
              <SelectArrayInput
                label="Notify of Contract Requests for these states"
                source="notifyContractStates"
                style={{ width: "90%" }}
                choices={statesList}
              />
            )}
            <BooleanInput
              label="Notify for new GA Contract requests"
              source="notifyGAContract"
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export const UserCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput source="firstName" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="lastName" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="preferredName" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="email" fullWidth />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SelectInput source="role" choices={roleOptions} />
              </Grid>

              <Grid item xs={6}>
                <AgencyInput />
              </Grid>
            </Grid>
            <NotificationControls />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="address" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="city" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <SelectInput source="state" choices={statesList} />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="zip" />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="phone" fullWidth />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
export default UserCreate;

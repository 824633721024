import React, { useState } from "react";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { Formik, Field, Form } from "formik";

import { Button, Alert } from "@mui/material";

import AuthService from "../../services/auth.service";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

const messageStyle = {
  marginBottom: 2,
};

const Forgot = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleReset = (data) => {
    setMessage("");
    setSuccess("");
    setLoading(true);

    AuthService.forgot(data.email).then(
      () => {
        setSuccess(
          "If an account with that email exists in our system, we’ll send instructions to reset the password"
        );
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setSuccess(
          "If an account with that email exists in our system, we’ll send instructions to reset the password"
        );
      }
    );
  };

  return (
    <div className="login-form">
      {success && (
        <Alert sx={messageStyle} severity="success">
          {success}
        </Alert>
      )}
      {message && (
        <Alert sx={messageStyle} severity="error">
          {message}
        </Alert>
      )}
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleReset(values);
        }}
      >
        {({ values, onBlur, onChange }) => (
          <Form>
            <Field
              name="email"
              label="E-Mail"
              fullWidth
              component={FormInput}
              variant="outlined"
            />
            <Button
              type="submit"
              disabled={!!loading}
              fullWidth
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Forgot;

import React, { useState, useEffect, useContext, createContext } from "react";
import AuthService from "../services/auth.service";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}


// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const ADMIN_ROLE = "admin";
  const AGENCY_ROLE = "agency";
  const SUBMIT_ROLE = "garity-submitonly";
  const STANDARD_ROLE = "garity-readonly";

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = await AuthService.getCurrentUser();
      if (userInfo) setUser(userInfo);
      setLoading(false);
    }
    // call the function
    fetchData()
  }, []);

  const isAdmin = (user) => {
    return ["admin", "agency"].includes(user?.role);
  }

  const isGarityAdmin = (user) => {
    return user?.role === ADMIN_ROLE;
  }

  const isAgencyAdmin = (user) => {
    return user?.role === AGENCY_ROLE;
  }

  const isSubmitOnlyUser = (user) => {
    return user?.role === SUBMIT_ROLE;
  }

  const isStandardUser = (user) => {
    return user?.role === STANDARD_ROLE || user?.role === SUBMIT_ROLE;
  }

  const canSubmit = (user) => {
    return user?.role !== STANDARD_ROLE;
  }

  const refetch = async () => {
      const userInfo = await AuthService.getCurrentUser();
      if (userInfo) setUser(userInfo);
  }

  const signout = async () => {
    await AuthService.logout();
    setUser(null);
  };

  // Return the user object and auth methods
  return {
    user,
    loading,
    refetch,
    signout,
    isAdmin,
    isGarityAdmin,
    isAgencyAdmin,
    isSubmitOnlyUser,
    isStandardUser,
    canSubmit
  };
}

import React, { useState, useEffect } from "react";
import carrierService from "../../services/carrier.service";
import CarrierSelection from "./CarrierSelection";
import { useAgency } from "../../hooks/useAgency";
import * as Yup from "yup";
import _ from "lodash";

import { Button, Typography } from "@mui/material";

const Carrier = ({ completeStep, steps, user, data }) => {
  // restore carrier data if it exists
  const initialCarrierData = data?.contracts || [];
  const [carriers, setCarriers] = useState(initialCarrierData);
  const [carrierList, setCarrierList] = useState();
  const [loading, setLoading] = useState(false);
  const { agency } = useAgency();

  const requiresState = (carrierId, carrierList) => {
    const carrierData = carrierList.find((c) => c.id === carrierId);
    return carrierData.states?.length > 0;
  };

  const validationSchema = Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required("Carrier is required"),
        states: Yup.array()
          .of(Yup.string())
          .when("value", {
            is: (value) => requiresState(value, carrierList),
            then: (schema) =>
              schema.min(1, "At least one state must be selected"),
            otherwise: (schema) => schema.optional(),
          }),
        policyEntity: Yup.string().required("Individual/Corp is required"),
        policyCreation: Yup.string().required("New/Transfer is required"),
        withRelease: Yup.boolean().when("policyCreation", {
          is: (value) => value === "transfer",
          then: (schema) => schema.required("With Release is required"),
          otherwise: (schema) => schema.optional(),
        }),
      })
    )
    .min(1);

  // Get carrier list (only happens on load)
  useEffect(() => {
    carrierService.list(user).then((data) => {
      const carrierData = data.map((c) => {
        return {
          ...c,
          requiresState: c.states?.length > 0,
        };
      });
      console.log("carrierData", carrierData);
      setCarrierList(carrierData);
    });
  }, [agency, user]);

  // Update carrier options when new option is selected
  const updateOptions = (option, carrier, event) => {
    setCarriers(
      carriers.map((c) => {
        if (c.value === carrier.value) {
          c[option] = event.target.value;
        }
        return c;
      })
    );
  };

  function decorateCarrier(c) {
    const targetCarrier = _.find(carrierList, { id: c.value });
    c.label = targetCarrier.value;
    return c;
  }

  const submit = () => {
    let data = {};
    setLoading(true);

    data = {
      contracts: carriers.map((c) => decorateCarrier(c)),
      agency: user?.agency,
    };
    completeStep(data);
  };

  if (!carrierList) return <></>;

  return (
    <>
      <CarrierSelection
        carrierList={carrierList}
        updateOptions={updateOptions}
        carriers={carriers}
        setCarriers={setCarriers}
      />

      <Typography sx={{ fontSize: 14, textAlign: "right", marginBottom: 10 }}>
        Submit release through{" "}
        <a
          href="https://form.jotform.com/220176694294059"
          target="_blank"
          rel="noreferrer"
        >
          this link
        </a>
      </Typography>
      <Button
        disabled={loading || !validationSchema.isValidSync(carriers)}
        fullWidth
        variant="contained"
        onClick={submit}
      >
        Next
      </Button>
    </>
  );
};

export default Carrier;

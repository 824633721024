import { Edit } from 'react-admin';

import AgencyFields from './Fields';
import { AgencyTitle } from '.';


export const AgencyEdit = (props) => (
    <Edit title={<AgencyTitle />} redirect="list" {...props}>
        <AgencyFields />
    </Edit>
);

export default AgencyEdit;

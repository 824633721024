import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  Alert,
  Snackbar,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import contractRequestService from "../../services/contract-request.service";
import { paymentLabels } from "../../lib/constants";

const THStyle = {
  fontWeight: "500",
};

const Review = ({ data, completeStep, user }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notes, setNotes] = useState("");

  // Submit contract request
  const submit = () => {
    setLoading(true);
    data.notes = notes;

    contractRequestService
      .request(data)
      .then((response) => {
        setLoading(false);
        completeStep(response);
        if (user) {
          setTimeout(() => {
            window.location.href = "/admin/contract-request";
          }, 2000);
        }
      })
      .catch((err) => {
        setErrorMessage(
          `There was a problem submitting the contract request: ${err.response.data.message}`
        );
        console.log(err);
        setLoading(false);
        return;
      });
  };

  return (
    <>
      <Typography variant="h6">Please review your contract request:</Typography>
      <TableContainer sx={{ marginTop: 1 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                NPN
              </TableCell>
              <TableCell>{data.npn}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                First Name
              </TableCell>
              <TableCell>{data.firstName || "N/A"}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Last Name
              </TableCell>
              <TableCell>{data.lastName}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Preferred Name
              </TableCell>
              <TableCell>{data.preferredName || "N/A"}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Phone
              </TableCell>
              <TableCell>{data.phone}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                E-Mail
              </TableCell>
              <TableCell>{data.email}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Upline
              </TableCell>
              <TableCell>{data.upline || "N/A"}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Top Of Hierarchy
              </TableCell>
              <TableCell>{data.topOfHierarchy || "N/A"}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Address
              </TableCell>
              <TableCell>{data.address}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={THStyle}>
                Payment Method
              </TableCell>
              <TableCell>{paymentLabels[data.payment]}</TableCell>
            </TableRow>
            {data.payment === "corporation" && (
              <>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={THStyle}>
                    Payment Corporation
                  </TableCell>
                  <TableCell>{data.paymentCorporation}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={THStyle}>
                    Payment NPN
                  </TableCell>
                  <TableCell>{data.paymentNpn}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={THStyle}>
                    Payment Tax ID
                  </TableCell>
                  <TableCell>{data.paymentTaxId}</TableCell>
                </TableRow>
              </>
            )}
            {data.payment === "upline" && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={THStyle}>
                  Payment Upline
                </TableCell>
                <TableCell>{data.paymentUpline}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ marginTop: 3 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Carrier Name</TableCell>
              <TableCell>States</TableCell>
              <TableCell>New/Transfer</TableCell>
              <TableCell>Individual/Corp</TableCell>
              <TableCell>Release</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.contracts?.map((carrier) => {
              return (
                <TableRow
                  key={carrier.label}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {carrier.label}
                  </TableCell>
                  <TableCell>{carrier.states?.join(", ") || "N/A"}</TableCell>
                  <TableCell>{carrier.policyCreation}</TableCell>
                  <TableCell>{carrier.policyEntity}</TableCell>
                  <TableCell>
                    {carrier.policyCreation === "transfer" &&
                      (carrier.withRelease
                        ? "With Release"
                        : "Without Release")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TextField
        rows={5}
        fullWidth
        multiline
        label="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        sx={{ marginTop: 3, marginBottom: 3 }}
      />

      <Button disabled={loading} fullWidth variant="contained" onClick={submit}>
        Submit
      </Button>
      {errorMessage && (
        <Snackbar
          open={!!errorMessage}
          onClose={() => setErrorMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setErrorMessage(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Review;

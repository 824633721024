import React, { useState, useEffect, useContext, createContext } from "react";
import { fetchAgencyLinkData } from "../services/agency-link.service";
import { useSearchParams } from "react-router-dom";

const agencyLinkContext = createContext();

export function ProvideAgencyLinkData({ children }) {
  const linkData = useProvideAgencyLinkData();
  return <agencyLinkContext.Provider value={linkData}>{children}</agencyLinkContext.Provider>;
}

export function useAgencyLinkData() {
  return useContext(agencyLinkContext);
}

export function useProvideAgencyLinkData() {
  const [linkData, setLinkData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams(window.location.search);

  useEffect(() => {
    const fetchData = async () => {
      const agencyLinkID = searchParams.get("agencyLink");
      if (!agencyLinkID) {
        setLoading(false);
        return;
      }
      const linkData = await fetchAgencyLinkData(agencyLinkID);
      if (linkData) setLinkData(linkData);
      setLoading(false);
    }
    // call the function
    fetchData()
  }, [searchParams]);

  return {
    linkData,
    loading,
  }
}

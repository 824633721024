import { useAuth } from "../../hooks/useAuth";
import { useAgency } from "../../hooks/useAgency";

export default function AgencyName() {
  const { user } = useAuth();
  const { agency } = useAgency();
  if (!agency) {
    return null;
  }
  return (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      {agency.name}{' '}
      -{' '}{user.email}
    </div>
  );
}

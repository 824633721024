import React, { useState, useEffect, useContext, createContext } from "react";
import { fetchCurrent } from "../services/agency.service";

const agencyContext = createContext();

export function ProvideAgency({ children }) {
  const linkData = useProvideAgency();
  return <agencyContext.Provider value={linkData}>{children}</agencyContext.Provider>;
}

export function useAgency() {
  return useContext(agencyContext);
}

export function useProvideAgency() {
  const [agency, setAgency] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log("agency provider: fetching agency");
      const agency = await fetchCurrent();
      if (agency) setAgency(agency);
      setLoading(false);
    }
    // call the function
    fetchData()
  }, []);

  return {
    agency,
    loading,
  }
}

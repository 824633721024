import _ from "lodash";

export const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const states = Object.keys(stateAbbreviations);

export const statesList = states.map((s) => {
  return { name: s, id: stateAbbreviations[s] };
});

export const paymentOptions = [
  { name: "Commission payable to me", id: "me" },
  { name: "Make payable to my corporation/LLC", id: "corporation" },
  { name: "Make payment to my upline (LOA)", id: "upline" },
  { name: "Pay same as current contract", id: "current" },
];

export const paymentLabels = _(paymentOptions)
  .keyBy("id")
  .mapValues("name")
  .value();

export const gaCarrierPaymentUnitOptions = [
  { name: "$", id: "dollar" },
  { name: "%", id: "percent" }
];

export const gaStatusOptions = [
  { name: "In SmartOffice", id: "complete" },
  { name: "Denied", id: "denied" },
  { name: "Submitted", id: "submitted" },
];

export  const contractStatusOptions = [
    { name: "Submitted", id: "submitted" },
    { name: "Closed", id: "closed" },
    { name: "In SmartOffice", id: "complete" },
  ];

export const contractStatusLabels = _(contractStatusOptions)
  .keyBy("id")
  .mapValues("name")
  .value();

  export const gaApprovalOptions = [
    { name: "Approved", id: "approved" },
    { name: "Denied", id: "denied" },
    { name: "Pending", id: "pending" },
  ];

export const gaApprovalLabels = _(gaApprovalOptions)
  .keyBy("id")
  .mapValues("name")
  .value();

export const carrierOptions = [
  { name: "New Individual Contract", id: "new" },
  { name: "New Corporate Contract", id: "corp" },
  { name: "Transfer W/ Release", id: "transferWithRelease" },
  { name: "Transfer W/O Release", id: "transferWithoutRelease" },
];

export const carrierTypeOptions = [
  { name: "New", id: "New" },
  { name: "Renewal", id: "Renewal" },
];

const productTypes = ["MA", "MS", "Life", "Other"];

export const productTypeOptions = productTypes.map((p) => {
  return { name: p, id: p };
});

export const roleOptions = [
  { id: "agency", name: "Agency Admin" },
  { id: "admin", name: "Admin" },
  { id: "garity-readonly", name: "Read-Only" },
  { id: "garity-submitonly", name: "Submit-Only" }
  // { id: "user", name: "Normal User" }
];

import axios from "axios";
const API_URL = "/api/contract-request/";

const request = (contractRequest) => {
    return axios
      .post(API_URL, {
        ...contractRequest
      })
      .then((response) => {
        return response.data;
      });
  };

const ContractRequestService = { request };

export default ContractRequestService;

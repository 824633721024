import { Datagrid, ExportButton, List, TextField, TopToolbar } from 'react-admin';

const ListActions = () => (
    <TopToolbar>
        <ExportButton/>
    </TopToolbar>
);

export const AgentList = () => (
    <List actions={<ListActions />}>
        <Datagrid rowClick="edit">
            <TextField source="npn" />
            <TextField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
        </Datagrid>
    </List>
);

export default AgentList;

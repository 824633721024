import { Typography } from "@mui/material";
import { ReferenceField, useRecordContext } from "react-admin";

export const UserDisplay = () => {
  const record = useRecordContext();
  if (!record.user) {
    return (
      <Typography variant="caption">
        This request was submitted by a user who was not logged in.
      </Typography>
    );
  }
  return (
    <ReferenceField source="user" reference="user" />
  );
};
